 
  .result-message {
    line-height: 22px;
    font-size: 16px;
    color: black;
  }

  .result-message-container {
    text-align: center;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px;
    border: 1px solid black;
    background-color: green;
  }

  .result-message-date {
    background-color: white;
    display: inline-block;
    padding: 2px;
    margin: 3px;
    border: 1px solid black;
    border-radius: 8px;
  }

  .result-message-container .result-message {
    font-size: 1.5em;
    line-height: 1.3;
  }

  
  .result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
  }
  
  .hidden {
    display: none;
  }
  
  .card-error {
    color: red;
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    text-align: center;
  }
  
  #card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    max-height: 44px;
    width: 100%;
    background: white;
    box-sizing: border-box;
  }
  
  